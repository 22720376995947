<template>
  <div justify="center">
    <v-icon small @click.stop="openForm">
      {{ icon }}
    </v-icon>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5" style="color: red">仕入先（削除）</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status == 'error'">
              <div v-for="msg of apierror.messages" :key="msg">
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row align="center">
              <v-col cols="12">
                <v-text-field v-model="selectedSupplier.sup_code" label="仕入先ID" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedSupplier.sup_name" label="仕入先名" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="selectedSupplier.sup_pic_mobile" label="携帯電話番号" readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedSupplier.sup_tel" label="固定電話番号" readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="selectedSupplier.sup_pic_email" label="Email" readonly></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn color="success" :loading="submitStatus" @click="deleteItem(selectedSupplier)">
            YES
          </v-btn>
          <v-btn color="error" class="mx-2" @click="closeForm()">
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

export default {
  props: ['icon', 'id'],
  data: () => ({
    submitStatus: false,
    dialog: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('supplierStore', ['selectedSupplier']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('supplierStore', ['loadSupplier', 'deleteSupplier']),
    ...mapMutations('supplierStore', ['clearSelectedSupplier']),
    openForm() {
      this.setOverlayStatus(true)
      this.loadSupplier(this.id)
        .then(() => {
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.submitStatus = false
          this.setOverlayStatus(false)
          this.dialog = true
        })
    },
    deleteItem(selectedSupplier) {
      // console.log(selectedSupplier)

      this.apierror.messages = []
      this.submitStatus = true
      this.deleteSupplier(selectedSupplier.id)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
    closeForm() {
      this.$emit('refreshList')
      this.apierror.messages = []
      this.dialog = false
      this.clearSelectedSupplier()
    },
  },
}
</script>
<style scoped>
/* .theme--light.v-label--is-disabled {
  color: rgba(241, 104, 12, 0.87)  !important;
}
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(15, 218, 140, 0.87)  !important;
} */
</style>
