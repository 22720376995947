<template>
  <div id="material-list">
    <v-card>
      <v-card-title> 仕入先（一覧） </v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="3">
          <v-autocomplete
            v-model="sup_code"
            placeholder="仕入れ先ID"
            :items="sup_codes"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <!-- <v-col cols="12" sm="3">
          <v-autocomplete
            v-model="sup_name"
            placeholder="仕入先"
            :items="sup_names"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col> -->
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          single-line
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'supplier-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn color="secondary" outlined class="mb-4 me-3">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="filteredTable"
        :headers="headers"
        :items="supplierList"
        class="elevation-1"
        :search="searchQuery"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <SupplierDelete
              :id="item.id"
              class="py-2 pl-1"
              :icon="icons.mdiDelete"
              @refreshList="refreshList"
            ></SupplierDelete>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
/* eslint-disable */
import { mdiPencil, mdiDelete, mdiExportVariant,mdiPlus } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import SupplierDelete from './SupplierDelete.vue'
/* eslint-disable */

export default {
  components: {
    SupplierDelete,
  },
  data: () => ({
    icons: { mdiPencil, mdiDelete, mdiExportVariant,mdiPlus },
    sup_code: '',
    sup_codes: [],
    // sup_name: '',
    // sup_names: [],
    searchQuery: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        sortable: true,
        value: 'id',
        width: '8%',
      },
      {
        text: '仕入先ID',
        align: 'left',
        sortable: true,
        value: 'sup_code',
        width: '15%',
      },
      {
        text: '略称',
        sortable: true,
        value: 'sup_name_abbr',
        align: 'left',
        width: '16%',
      },
      {
        text: '固定電話',
        sortable: true,
        value: 'sup_tel',
        align: 'left',
        width: '16%',
      },
      {
        text: '担当者',
        value: 'sup_pic_name',
        align: 'left',
        width: '19%',
      },
      {
        text: '携帯電話',
        value: 'sup_pic_mobile',
        align: 'left',
        width: '18%',
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'right',
        width: '8%',
      },
    ],
  }),
  computed: {
    ...mapState('supplierStore', ['supplierList']),
  },
  watch: {
    sup_code(newValue) {
      this.changeRouteQuery('sup_code', newValue)
      this.refreshList()
    },
    // sup_name(newValue) {
    //   this.changeRouteQuery('sup_name', newValue)
    //   this.refreshList()
    // },
  },
  created() {
    this.changeRouteQuery()
    this.loadQuery()
    this.refreshList()
  },
  destroyed() {
    this.clearSupplierList()
  },
  methods: {
    ...mapActions('supplierStore', ['loadSupplierList']),
    ...mapMutations('supplierStore', ['clearSupplierList']),
    ...mapMutations('app', ['setOverlayStatus']),

    refreshList() {
      this.setOverlayStatus(true)
      this.loadSupplierList(this.$route.query).then(() => {
        this.sup_codes = this.supplierList.map(item => item.sup_code)
        // this.sup_names = this.supplierList.map(item => item.sup_name)
        // console.log('refreshList', this.supplierList)
      }).finally(()=>{
        this.setOverlayStatus(false)
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({ path: this.$route.path, query })
    },

    loadQuery() {
      this.sup_code = this.$route.query.sup_code ?? ''
      // this.sup_name = this.$route.query.sup_name ?? ''
    },

    editItem(item) {
      this.$router.push({ name: 'supplier-edit', params: { id: item.id } })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
